<template>
  <div class="hello">
    <h1>Welcome to E-Muffasir Search Engine</h1>
    <p>Disini adalah tempat mencari Rujukan Islam berdasarkan Al-Qur'an dan Hadits</p>
    <div class="search-container">
      <input type="text" v-model="searchQuery" placeholder="Cari Rujukan Islam..." class="search-input" @keyup.enter="search">
      <button @click="search" class="search-button">
        <i class="fas fa-search"></i> Cari
      </button>
    </div>
  </div>
  <Footer />
</template> 

<script>
import Footer from '@/components/Footer.vue';

export default {
    name: 'SearchView',
    data() {
        return {
            searchQuery: ''
        };
    },
    methods: {
        search() {
            this.$router.push({ name: 'hasil', params: { query: this.searchQuery } });
        }
    },
    Footer,
    components: { Footer }
};
</script>

<style scoped>
.hello {
  text-align: center;
  margin-top: 100px;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  margin-bottom: 30px;
}

.search-container {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  width: 70%;
  font-size: 16px;
}

.search-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
}

.search-button i {
  margin-right: 5px;
}
</style>
