<template>
  <Header />
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css?v=2.8" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous" />

  <section class="banner w-100 overflow-hidden">
    <div class="content-top">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-11 col-11">
          <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner rounded-5">
              <div class="carousel-item active">
                <img src="assets/images/carousel/image5.png" class="d-block img-fluid" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="assets/images/carousel/image2.png" class="d-block img-fluid" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="assets/images/carousel/image4.png" class="d-block img-fluid" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="assets/images/carousel/image6.png" class="d-block img-fluid" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="assets/images/carousel/image1.png" class="d-block img-fluid" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="assets/images/carousel/image3.png" class="d-block img-fluid" alt="..." />
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="team-section mt-4">
    <div id="Quran" class="container">
      <div class="row">
        <div class="col-12">
          <div class="header-title mb-4">
            <h2><strong>KEISLAMAN</strong></h2>
          </div>
        </div>
        <div class="col-12">
          <div class="header-title mb-4">
            <h3>Menyediakan Pengetahuan Seputar Keislaman berdasarkan Al-Qur'an dan Hadits</h3>
          </div>
        </div>
        <div class="col-12">
          <div class="row row-cols-2 row-cols-md-3 g-lg-4 g-1 justify-content-center">
            <router-link to="/cari" class="col justify-content-center">
              <div class="card-quran text-center border-dark team-item-1 rounded-3 shadow">
                <div class="d-flex py-4 align-items-center justify-content-around p-4">
                  <div class="d-flex">
                    <h2 class="card-title-quran"></h2>
                  </div>
                  <div class="d-flex flex-column">
                    <h3 class="">Search Engine Keislaman</h3>
                    <p class="">Cari Apa Saja tentang Islam</p>
                    <p class=""><small></small></p>
                  </div>
                </div>
              </div>
            </router-link>
            <a href="https://rujukan-islam.e-mufassir.com/">
              <div class="col justify-content-center">
                <div class="card-quran text-center border-dark team-item-1 rounded-3 shadow">
                  <div class="d-flex py-4 align-items-center justify-content-around p-4">
                    <div class="d-flex">
                      <h2 class="card-title-quran"></h2>
                    </div>
                    <div class="d-flex flex-column">
                      <h3 class="">Chatbot Keislaman</h3>
                      <p class="">Tanya apa saja Tentang Islam</p>
                      <p class=""><small></small></p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "../components/Header.vue";

export default {
  name: "HomeView",
  components: {
    Header,
  },
};
</script>

<style scoped>
section {
  margin-top: 182px;
}
a {
  text-decoration: none;
}
</style>
