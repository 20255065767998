import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SearchView from "../views/SearchView.vue";
import ResultView from "../views/ResultView.vue";
import DetailView from "../views/DetailView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/cari",
      name: "cari",
      component: SearchView,
    },
    {
      path: "/cari/:query",
      name: "hasil",
      component: ResultView,
      props: true,
    },
    {
      path: "/detail/:activeTab/:contentId",
      name: "detail",
      component: DetailView,
      props: true,
    },
  ],
});

export default router;
