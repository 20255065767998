<template>
<!-- Header Section Starts Here -->
<header class="header-3 pattern-1 position-fixed top-0 start-0 end-0">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-2 col-12">
                <div class="mobile-menu-wrapper d-flex flex-wrap align-items-center justify-content-between">
                    <div class="header-bar d-lg-none">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="logo">
                        <a href="index.html">
                            <img src="assets/images/logo.png
                            " alt="logo">
                        </a>
                    </div>
                    <div class="donate text-center d-lg-none">
                        <i class="ri-hand-heart-line"></i>
                        <small>donasi</small>
                    </div>
                </div>
            </div>
            <div class="col-xl-10 col-12">
                <div class="header-top">
                    <div class="header-top-area">
                        <ul class="left lab-ul mb-3">
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="header-bottom">
                    <div class="header-wrapper">
                        <div class="menu-area justify-content-between w-10">
                            <ul class="menu lab-ul">
                                <li class="list">
                                    <a href='https://e-mufassir.com'>E-Mufassir</a>
                                </li>
                                <li class="list">
                                    <a href="/">Keislaman</a>
                                </li>
                                <li class="list">
                                    <a href="/cari">Search Engine</a>
                                </li>
                                <li class="list">
                                    <a href="#0">Chatbot Keislaman</a>
                                </li>
                                <li class="donasi d-none d-lg-block">
                                    <button type="button" class="btn">
                                        <a href="" style="color: white; font-weight: bold">Donasi
                                            <i class="ri-hand-heart-fill"></i></a>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
export default{
    name : 'HeaderComponent',
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>