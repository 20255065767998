<template>
  <div class="detail-view" v-cloak>
    <h1>{{ activeTab === "alquran" ? "Alquran" : "Hadits" }}</h1>
    <div v-if="activeTab === 'alquran'" class="detail-content">
      <div class="detail-item">
        <p class="detail-text">Surah: {{ detailData.chapter_name }} Ayat: {{ detailData.verse_number }}</p>
      </div>
      <div class="detail-item">
        <label class="detail-label">Ayat:</label>
        <p class="detail-text">{{ detailData.text_uthmani }}</p>
      </div>
      <div class="detail-item" v-if="!tafsirOnly">
        <label class="detail-label">Terjemahan Ayat:</label>
        <p class="detail-text">{{ detailData.translation_text }}</p>
      </div>
      <div class="detail-item" v-else>
        <label class="detail-label">Tafsiran Ayat:</label>
        <p class="detail-text">{{ detailData.tafsir_text }}</p>
      </div>
    </div>
    <div v-else-if="activeTab === 'hadits'" class="detail-content">
      <div class="detail-item">
        <label class="detail-label">Kitab:</label>
        <p class="detail-text">{{ detailData.kitab }}</p>
      </div>
      <div class="detail-item">
        <label class="detail-label">Detail Kitab:</label>
        <p class="detail-text">{{ detailData.kitab_id }}</p>
      </div>
      <div class="detail-item">
        <label class="detail-label">Bab:</label>
        <p class="detail-text">{{ detailData.bab }}</p>
      </div>
      <div class="detail-item">
        <label class="detail-label">Arab:</label>
        <p class="detail-text">{{ detailData.arab }}</p>
      </div>
      <div class="detail-item">
        <label class="detail-label">Terjemah:</label>
        <p class="detail-text">{{ detailData.terjemah }}</p>
      </div>
    </div>
    <div v-else>
      <p>Tab tidak valid</p>
    </div>
    <div class="action-buttons">
      <button class="action-button" @click="copyData"><i class="fas fa-copy"></i> Salin</button>
      <button class="action-button" @click="openShareModal"><i class="fas fa-share"></i> Bagikan</button>
    </div>
    <div v-if="showShareModal" class="modal-backdrop fade show" aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Bagikan Rujukan</h5>
            <button type="button" class="btn-close" @click="closeShareModal" aria-label="Tutup">x</button>
          </div>
          <div class="modal-body d-flex justify-content-around">
            <a :href="whatsappLink" target="_blank">
              <i class="ri-whatsapp-fill ri-4x" style="color: #0ae77b"></i>
            </a>
            <a :href="telegramLink" target="_blank">
              <i class="ri-telegram-fill ri-4x" style="color: #289fd7"></i>
            </a>
            <a :href="facebookLink" target="_blank">
              <i class="ri-facebook-circle-fill ri-4x" style="color: #3c589c"></i>
            </a>
            <a :href="twitterLink" target="_blank">
              <i class="ri-twitter-fill ri-4x" style="color: #24a3f1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DetailView",
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      detailData: {},
      tafsirOnly: false,
      showShareModal: false,
    };
  },
  mounted() {
    this.tafsirOnly = this.$route.query.tafsirOnly === "true";
    this.getDetailData();
  },
  methods: {
    async getDetailData() {
      const endpoint = `https://backend-keislaman.e-mufassir.com/detail/${this.activeTab}/${this.contentId}`;
      try {
        const response = await axios.get(endpoint);
        this.detailData = response.data || {};
        if (this.tafsirOnly && this.activeTab === "alquran" && !response.data.tafsir_text) {
          console.warn("Tafsir tidak tersedia untuk ayat ini.");
        }
      } catch (error) {
        console.error("Error fetching detail data:", error);
        this.detailData = {};
      }
    },
    copyData() {
      let content = "";
      if (this.activeTab === "alquran") {
        content = `${this.detailData.chapter_name} - ${this.detailData.verse_number}: 
${this.detailData.text_uthmani} 
${this.tafsirOnly ? this.detailData.tafsir_text : this.detailData.translation_text}`;
      } else if (this.activeTab === "hadits") {
        content = `${this.detailData.kitab} - ${this.detailData.bab}: ${this.detailData.kitab_id}
${this.detailData.arab} 
${this.detailData.terjemah}`;
      }
      navigator.clipboard
        .writeText(content)
        .then(() => {
          console.log("Data berhasil disalin ke papan klip.");
        })
        .catch((error) => {
          console.error("Error copying data:", error);
        });
    },
    openShareModal() {
      this.showShareModal = true;
    },
    closeShareModal() {
      this.showShareModal = false;
    },
  },
  computed: {
    whatsappLink() {
      const baseText =
        this.activeTab === "alquran"
          ? `${this.detailData.chapter_name} ${this.detailData.verse_number}\n${this.detailData.text_uthmani}\n${this.tafsirOnly ? this.detailData.tafsir_text : this.detailData.translation_text}`
          : `${this.detailData.kitab} ${this.detailData.bab} ${this.detailData.kitab_id}\n${this.detailData.arab}\n${this.detailData.terjemah}`;
      return `https://wa.me/?text=${encodeURIComponent(baseText)}`;
    },
    telegramLink() {
      const baseText =
        this.activeTab === "alquran"
          ? `${this.detailData.chapter_name} ${this.detailData.verse_number}\n${this.detailData.text_uthmani}\n${this.tafsirOnly ? this.detailData.tafsir_text : this.detailData.translation_text}`
          : `${this.detailData.kitab} ${this.detailData.bab} ${this.detailData.kitab_id}\n${this.detailData.arab}\n${this.detailData.terjemah}`;
      return `https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(baseText)}`;
    },
    facebookLink() {
      const url = encodeURIComponent(window.location.href);
      return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    },
    twitterLink() {
      const baseText = this.activeTab === "alquran" ? `${this.detailData.chapter_name} ${this.detailData.verse_number}` : `${this.detailData.kitab} ${this.detailData.bab}`;
      const url = encodeURIComponent(window.location.href);
      return `https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(baseText)}`;
    },
  },
};
</script>

<style scoped>
.detail-view {
  font-family: "Arial", sans-serif;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #4caf50;
  position: relative;
}

h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.detail-content {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.detail-item {
  margin-bottom: 15px;
}

.detail-label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.detail-text {
  font-size: 14px;
  color: #333;
  margin-top: 5px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.action-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0056b3;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-dialog {
  width: 90%;
  max-width: 400px;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  font-size: 18px;
  margin: 0;
}

.btn-close {
  padding: 5px;
  margin: -5px -5px -5px auto;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 1.5rem;
}

.modal-body {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

[v-cloak] {
  display: none;
}
</style>
