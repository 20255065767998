<template>
  <div class="result-view">
    <div class="search-bar">
      <input type="text" v-model="query" placeholder="Search..." @keyup.enter="startSearch" />
      <button @click="startSearch">Search</button>
    </div>
    <div class="tab">
      <button :class="{ active: activeTab === 'alquran' }" @click="changeTab('alquran')">Alquran</button>
      <button :class="{ active: activeTab === 'hadits' }" @click="changeTab('hadits')">Hadits</button>
    </div>
    <hr class="divider" />
    <div class="filter">
      <label class="filter-option"> <input type="checkbox" v-model="filters.real" :disabled="filters.tafsirOnly" /> Asli </label>
      <label class="filter-option"> <input type="checkbox" v-model="filters.relevan" :disabled="filters.tafsirOnly" /> Relevan dengan ('{{ query }}') </label>
      <template v-if="activeTab === 'alquran'">
        <label class="filter-option"> <input type="checkbox" v-model="filters.tafsirOnly" @change="handleTafsirOnlyChange" /> Hanya Tafsir dari Kata ('{{ query }}') </label>
        <button class="filter-button alquran-filter-button" @click="applyFilters">Filter</button>
      </template>
      <template v-if="activeTab === 'hadits'">
        <button class="filter-button hadits-filter-button" @click="applyFilters">Filter</button>
      </template>
    </div>
    <div class="result-info" v-if="searchResults[activeTab].length > 0">
      <template v-if="!loading">
        <template v-if="!filters.real && !filters.relevan && !filters.tafsirOnly"> Sekitar {{ formatNumber(searchResults[activeTab].length) }} hasil ({{ searchTime }} detik) </template>
        <template v-else> Sekitar {{ formatNumber(filteredResults.length) }} hasil ({{ searchTime }} detik) </template>
      </template>
    </div>
    <div class="results">
      <div v-if="loading" class="loading">
        <div class="spinner"></div>
      </div>
      <div v-if="!loading">
        <div v-if="searchResults[activeTab].length > 0">
          <div v-for="(result, index) in paginatedResults" :key="index" class="result-item" @click="viewContent(result)">
            <h3 v-if="activeTab === 'alquran'" class="text-uthmani">{{ result.chapterName }} ayat : {{ result.numberVerse }}</h3>
            <p v-if="activeTab === 'alquran' && !filters.tafsirOnly" v-html="highlightKeywords(truncateText(result.translationText, 200), query.split(/\s+/))"></p>
            <p v-if="activeTab === 'alquran' && filters.tafsirOnly" v-html="highlightKeywords(truncateText(result.tafsirText, 200), query.split(/\s+/))"></p>
            <h3 v-if="activeTab === 'hadits'">{{ result.kitab }}</h3>
            <p v-if="activeTab === 'hadits'">{{ result.bab }}</p>
            <p v-if="activeTab === 'hadits'">{{ truncateText(result.terjemah, 200) }}</p>
          </div>
        </div>
        <div v-else class="no-results">No results found.</div>
      </div>
    </div>
    <div class="pagination">
      <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  data() {
    return {
      query: "",
      activeTab: "alquran",
      searchResults: {
        alquran: [],
        hadits: [],
        tafsir: [],
      },
      currentPage: 1,
      itemsPerPage: 15,
      searchTime: 0,
      filters: {
        real: false,
        relevan: false,
        tafsirOnly: false,
      },
      filteredResults: [],
      loading: false,
    };
  },

  mounted() {
    this.query = this.$route.params.query;
    this.activeTab = this.$route.query.tab || "alquran";
    this.startSearch();
  },

  computed: {
    totalPages() {
      return Math.ceil(this.filteredResults.length / this.itemsPerPage);
    },
    paginatedResults() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      return this.filteredResults.slice(startIndex, endIndex);
    },
  },

  methods: {
    async startSearch() {
      if (this.query.trim() !== "") {
        this.search();
      }
    },

    async search() {
      this.loading = true;
      try {
        const startTime = performance.now();

        const responseAlquran = await axios.get(`https://backend-keislaman.e-mufassir.com/search/alquran?q=${this.query}`);
        const responseHadits = await axios.get(`https://backend-keislaman.e-mufassir.com/search/hadits?q=${this.query}`);
        const responseTafsir = await axios.get(`https://backend-keislaman.e-mufassir.com/search/tafsir?q=${this.query}`);
        const endTime = performance.now();
        const searchDuration = ((endTime - startTime) / 1000).toFixed(2);
        this.searchTime = searchDuration;

        this.searchResults = {
          alquran: responseAlquran.data.map((hit) => ({
            id: hit._id,
            idVerse: hit._source.id_verse,
            chapterName: hit._source.chapter_name,
            numberVerse: hit._source.verse_number,
            textUthmani: hit._source.text_uthmani,
            translationText: hit._source.translation_text,
            keyword: this.query,
            originalKeyword: this.query,
          })),
          hadits: responseHadits.data.map((hit) => ({
            id: hit._id,
            kitab: hit._source.kitab,
            kitabId: hit._source.kitab_id,
            bab: hit._source.bab,
            terjemah: hit._source.terjemah,
            arab: hit._source.arab,
            keyword: this.query,
            originalKeyword: this.query,
          })),
          tafsir: responseTafsir.data.map((hit) => ({
            id: hit._id,
            idVerse: hit._source.id_verse,
            chapterName: hit._source.chapter_name,
            numberVerse: hit._source.verse_number,
            textUthmani: hit._source.text_uthmani,
            tafsirText: hit._source.tafsir_text,
            keyword: this.query,
            originalKeyword: this.query,
          })),
        };

        this.applyFilters();
        this.loading = false;

        if (this.query.trim() !== "") {
          this.$router.push({ name: "hasil", params: { query: this.query }, query: { tab: this.activeTab } });
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        this.loading = false;
      }
    },

    changeTab(tab) {
      // Reset tafsirOnly filter when switching to Hadits tab
      if (tab === "hadits") {
        this.filters.tafsirOnly = false; // Reset filter
      }
      this.activeTab = tab;
      this.$router.push({ query: { tab: this.activeTab } });
      this.applyFilters();
    },

    viewContent(result) {
      const routeParams = { name: "detail", params: { activeTab: this.activeTab, contentId: result.id } };

      if (this.filters.tafsirOnly) {
        routeParams.query = { tafsirOnly: this.filters.tafsirOnly };
      }

      router.push(routeParams);
    },

    truncateText(text, length) {
      if (!text) {
        return "";
      }
      if (text.length <= length) {
        return text;
      } else {
        return text.substring(0, length) + "...";
      }
    },

    highlightKeywords(text, keywords) {
      return text.replace(new RegExp(`(${keywords.join("|")})`, "gi"), '<span class="highlight">$1</span>');
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    previousPage() {
      this.currentPage--;
    },

    nextPage() {
      this.currentPage++;
    },

    applyFilters() {
      const allResults = this.filters.tafsirOnly ? this.searchResults.tafsir : this.searchResults[this.activeTab];
      let filteredResults = allResults;

      if (this.filters.real) {
        filteredResults = filteredResults.filter((result) => this.isExactMatch(result));
      }

      if (this.filters.relevan) {
        const exactMatches = [];
        const synonymMatches = [];

        filteredResults.forEach((result) => {
          const isExactMatch = this.isExactMatch(result);
          if (isExactMatch) {
            exactMatches.push(result);
          } else {
            synonymMatches.push(result);
          }
        });

        filteredResults = exactMatches.concat(synonymMatches);
      }

      this.filteredResults = filteredResults;
    },

    handleTafsirOnlyChange() {
      if (this.filters.tafsirOnly) {
        this.filters.real = false;
        this.filters.relevan = false;
      }
      this.applyFilters(); // Apply filters immediately after changing tafsirOnly
    },

    isExactMatch(result) {
      if (this.activeTab === "alquran") {
        const { chapterName, translationText, textUthmani, tafsirText } = result;
        const query = this.query.toLowerCase();
        return chapterName.toLowerCase().includes(query) || translationText.toLowerCase().includes(query) || textUthmani.toLowerCase().includes(query) || (this.filters.tafsirOnly && tafsirText.toLowerCase().includes(query));
      } else if (this.activeTab === "hadits") {
        const { kitab, bab, terjemah, arab } = result;
        const query = this.query.toLowerCase();
        return kitab.toLowerCase().includes(query) || bab.toLowerCase().includes(query) || terjemah.toLowerCase().includes(query) || arab.toLowerCase().includes(query);
      }
    },
  },
};
</script>

<style scoped>
.result-view {
  align-items: start;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-bar input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 24px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.search-bar input:focus {
  border-color: #4caf50;
}

.search-bar button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #388e3c;
}

.tab {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tab button.active {
  background-color: #4caf50;
  color: #fff;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.results {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.result-item {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
}

.result-item:hover {
  transform: translateY(-3px);
}

.result-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.result-item p {
  font-size: 16px;
  color: #70757a;
}

.result-info {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #70757a;
}

.no-results {
  text-align: center;
  color: #70757a;
  margin-top: 20px;
}

.divider {
  border: 1px solid #ccc;
  margin: 20px 0;
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-option {
  margin-right: 10px;
}

.alquran-filter-button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.alquran-filter-button:hover {
  background-color: #388e3c;
}

/* Hadits filter button styles */
.hadits-filter-button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hadits-filter-button:hover {
  background-color: #388e3c;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination button {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover {
  background-color: #f1f3f4;
}

/* CSS for tablet view */
@media screen and (max-width: 768px) {
  .result-view {
    padding: 10px;
  }

  .search-bar input {
    padding: 10px;
  }

  .search-bar button {
    padding: 10px 15px;
  }

  .tab button {
    padding: 8px 15px;
  }

  .result-item {
    padding: 15px;
  }

  .result-item h3 {
    font-size: 16px;
  }

  .result-item p {
    font-size: 14px;
  }

  .result-info {
    font-size: 12px;
  }

  .pagination button {
    padding: 8px 15px;
  }
}

/* CSS for mobile view */
@media screen and (max-width: 576px) {
  .search-bar input {
    padding: 8px;
    font-size: 14px;
  }

  .search-bar button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .tab button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .result-item {
    padding: 10px;
  }

  .result-item h3 {
    font-size: 14px;
  }

  .result-item p {
    font-size: 12px;
  }

  .result-info {
    font-size: 10px;
  }

  .pagination button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
</style>
