<template>
    <footer class="footer-section" style="background-image: url(assets/images/bg-images/footer-bg.png);">
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-bottom-content text-center">
                        <p>&copy;2024 <a href="https://e-mufassir.com">E-Mufassir</a> - Search Engine.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default{
    name : 'FooterComponent',
}
</script>

<style scooped>
.footer-section{
margin-top: 300px;
}
</style>